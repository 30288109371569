@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Futura";
  src: url("../public/fonts/FuturaStdLight.ttf");
}

@font-face {
  font-family: "FuturaBold";
  src: url("../public/fonts/FuturaPTMedium.ttf");
}
body {
  margin: 0;
  font-family: "Futura";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Futura";
}
